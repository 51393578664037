import { FC, PropsWithChildren, useRef, useState } from "react"
import ContentRow from "../../components/ContentRow"
import { Button, } from "react-bootstrap"
import { IGood, TResult } from "../../store/goods/types"
import { ResultToString } from "../../App"
import ItemsTable from "../../components/ItemsTable"
import { messageUtils } from "../../api/utils"

interface IAutoViewProps {
    lastStatus: TResult
    totalItems: number
    foundItems: number
    items: IGood[] | null
    isLoading: boolean
    lastCode: string | null
    rightTitleChildren?: any

    clearItems?: () => void
}

const AutoView: FC<PropsWithChildren<IAutoViewProps>> = (props) => {


    return (
        <>
            <ContentRow className={`App-title-placeholder ${ResultToString(props.lastStatus).color}`}>
                <div className={`nakStatus border border-1 ${props.foundItems == props.totalItems ? 'text-white bg-success' : 'text-dark bg-white'}`}>{props.foundItems}/{props.totalItems}</div>
                {props.rightTitleChildren ? <div className={`nakStatusRight border border-1`}>{props.rightTitleChildren}</div> : null}
                <div className="p-2 rounded bg-primary mb-1 text-white">
                    Авторежим
                </div>
                {props.lastStatus
                    ? <div className="d-flex flex-row align-items-center">
                        <h6 className="me-2">Код:</h6>
                        <h5>{props.lastCode}</h5>
                    </div>
                    : null}
            </ContentRow>
            {props.children ? <ContentRow className="App-input-placeholder">{props.children}</ContentRow> : null}
            <ContentRow
                className={`App-content-placeholder table-responsive`}
                justifyContent={props.items?.length && !props?.isLoading ? "start" : "center"}
            >
                {
                    !props.items?.length && !props?.isLoading
                        ? <div className="d-flex flex-column justify-content-center align-items-center">
                            <h4>Сканируйте</h4>
                            <h6>или вносите коды вручную</h6>
                            <p>Они будут появляться и синхронизироваться с сервером</p>
                        </div>
                        : null
                }
                {
                    props.isLoading
                        ? <p>Загрузка...</p>
                        : (props?.items?.length
                            ? <ItemsTable
                                items={props.items}
                                mode="auto"
                                clearTable={() => props.clearItems && props.clearItems()}
                            />
                            : null)
                }
            </ContentRow>
        </>
    )
}

export default AutoView