import { useEffect, useState } from 'react';
import './App.css';
import { Container, Nav, } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getAsyncGoodsCreator, } from './store/goods/actions';
import { useDispatch, useSelector } from 'react-redux';
import { StorageType } from './store/rootReducer';
import { IGoodsState, TResult } from './store/goods/types';
import { Outlet, useParams } from 'react-router-dom';
import { useDebounce } from './helpers/helpers';
import { EMPTY_DOC_ID } from './store/goods/constants';

// const TState: "norequest" | "found" | "notfound" | "alreadyFound" | "autoModeScanned" = "norequest"

export const ResultToString = (result: TResult): { text: string, color: string } => {
  switch (result) {
    case "init": return { text: "Не сканировалось", color: "bg-white" };
    case "error": return { text: "Ошибка", color: "bg-danger" };
    case "exist": return { text: "Уже существует", color: "bg-warning" };
    case "found": return { text: "Найдено", color: "bg-success" };
    case "not_found": return { text: "Не найдено", color: "bg-danger" };
  }
}

function App(props: { clickHandler?: () => void }) {
  const { id } = useParams()

  const goodState: IGoodsState = useSelector((state: StorageType) => state.goods)
  const [barcodeTerm, setBarcodeTerm] = useState('')
  const debouncedTerm = useDebounce(barcodeTerm, 1000)


  const [inputGood, setInputGood] = useState<string>("")
  const [lastGood, setLastGood] = useState<string>("")
  const dispatch = useDispatch()

  useEffect(
    () => {
      if (debouncedTerm !== undefined && barcodeTerm !== undefined) {
        // отправка кода
        setChecked(barcodeTerm)
      }
    },
    [debouncedTerm]
  )

  useEffect(() => {
    const str = id?.trim() ?? ""
    if (str != "") {
      changeDoc(str)
    }
  }, [id])

  // const openModal = () => {

  //   // находим найденные, но не отсинхронизированные
  //   const allSynced = goodState.items.filter(g => g.found && !g.synced)
  //   if (allSynced && allSynced.length > 0) {
  //     window.alert("Не все отсканированные заказы отсинхронизированы. Необходимо восстановить соединение с сервером, чтобы синхронизация завершилась.")
  //   }
  //   else {
  //     setInputDoc("")
  //     setShowModal(true)
  //   }
  // }

  // const openManualModal = () => {
  //   // находим найденные, но не отсинхронизированные
  //   const allSynced = goodState.items.filter(g => g.found && !g.synced)
  //   if (allSynced && allSynced.length > 0) {
  //     window.alert("Не все отсканированные заказы отсинхронизированы. Необходимо восстановить соединение с сервером, чтобы синхронизация завершилась.")
  //   }
  //   else {
  //     setManualInputDoc("")
  //     setShowManualModal(true)
  //   }
  // }

  const changeDoc = (docum: string) => {
    dispatch(getAsyncGoodsCreator(docum))
    // inp?.current?.focus();
  }

  useEffect(() => {

  }, [lastGood])

  useEffect(() => {
    // if (inputGood.length > 0){
    //   goodState.isAutoMode
    //     ? setBarcodeTerm(inputGood)
    //     : setChecked(inputGood)
    // }

  }, [inputGood])



  let textStatus = <p>Не было сканирований</p>
  let classStatus = "bg-white"

  const setChecked = (barcode: string) => {
    // const rowResult = goodState.items.filter(i => i.barcode == barcode)


    // if (rowResult?.length) {
    //   if (rowResult[0].found || goodState.isAutoMode) {
    //     setLastState("alreadyFound")
    //     inp.current.value = ""
    //     play2()
    //   }
    //   else {
    //     dispatch(setGoodCheckCreator(barcode))
    //     dispatch(setAsyncLogGoodScanCreator())
    //     setLastState("found")
    //     inp.current.value = ""
    //   }
    // }
    // else {
    //   if (goodState.isAutoMode){
    //     dispatch(addGoodCheckCreator(barcode))
    //     dispatch(setAsyncLogGoodScanCreator())
    //     setLastState("autoModeScanned")
    //     inp.current.value = ""
    //   }
    //   else{
    //     if (barcode?.length){
    //       setLastState("notfound")
    //       play1()
    //     }
    //   }
    // }

    // setLastGood(barcode)
    // setInputGood("")
    // inp?.current?.focus();
  }

  return (
    // <Container onClick={() => !showModal && inp?.current?.focus()}>
    <Container onClick={() => props.clickHandler && props.clickHandler()}>
      {/* <div
        className="fs-6 bg-warning px-2 py-1 rounded text-dark"
      style={{ position: 'absolute', top: '5px', left: '10px' }}
      >WS</div> */}
      <Nav variant='tabs' defaultActiveKey='/manual'>
        <Nav.Item>
          <Nav.Link href="/" active={goodState.mode == "manual"}>Ручной</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href={`/id/${EMPTY_DOC_ID}`} active={goodState.mode == "document"}>Документ</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="/auto" active={goodState.mode == "auto"}>Авто</Nav.Link>
        </Nav.Item>
        <div
          className="fs-10 bg-secondary px-2 py-1 rounded text-light"
          style={{ position: 'absolute', top: '5px', right: '10px' }}
        >v 2.2</div>
      </Nav>


      <Outlet />




    </Container>
  );
}

export default App;
