import { createAction } from "@reduxjs/toolkit";
import { customerActionTypes as actionTypes, BarcodeAndDocument, IGoodsState, TMode, TResult } from "./types"

export const SET_MODE = actionTypes.SetMode;
export const SET_IS_SCANNER_MODE = actionTypes.SetScannerMode;
export const SET_LAST_RESULT = actionTypes.SetLastResult;
export const ASYNC_GET_GOODS = actionTypes.GetGoods;
export const ASYNC_LOG_GOOD_SCAN_START = actionTypes.LogGoodScanStart;
export const MANUAL_GET_GOODS = actionTypes.ManualSetGoods;
export const SET_DOC = actionTypes.SetDocument;
export const CLEAR_DOC = actionTypes.ClearDocument;
export const SET_GOOD_CHECK = actionTypes.SetGoodCheck;
export const ADD_GOOD_CHECK = actionTypes.AddGoodCheck;
export const SET_AUTO_MODE = actionTypes.SetAutoMode;



export const getAsyncGoodsCreator = createAction<string>(ASYNC_GET_GOODS);
export const setModeCreator = createAction<TMode>(SET_MODE);
export const setIsScannerModeCreator = createAction<boolean>(SET_IS_SCANNER_MODE);
export const setLastResultCreator = createAction<TResult>(SET_LAST_RESULT);
export const setManualGoodsCreator = createAction<string>(MANUAL_GET_GOODS);
export const setDocumentCreator = createAction<string>(SET_DOC);
export const clearDocumentCreator = createAction(CLEAR_DOC);
export const setGoodCheckCreator = createAction<string>(SET_GOOD_CHECK);
export const addGoodCheckCreator = createAction<string>(ADD_GOOD_CHECK);
// export const setGoodCheckStartCreator = createAction(SET_GOOD_CHECK_START);
export const setAsyncLogGoodScanCreator = createAction(ASYNC_LOG_GOOD_SCAN_START);
export const setAutoModeCreator = createAction<boolean>(SET_AUTO_MODE);

