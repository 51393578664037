import React, { FC } from 'react'
import { Link } from 'react-router-dom'

interface IMessagePageProps{
  name?: string
  info?: string
  btnTitle?: string
  btnHref?: string
}

const MessagePage: FC<IMessagePageProps> = (props) => {
  return (
    <div style={{height: '100vh'}} className='w-100 d-flex flex-column justify-content-center align-items-center'>
      <h1>{props.name ?? ""}</h1>
      <p>{props.info ?? ""}</p>
      <Link to={props.btnHref ?? "/"}>{props.btnTitle ?? "Перейти"}</Link>
    </div>
  )
}

export default MessagePage