import React from 'react'
import { Link } from 'react-router-dom'

const ErrorsPage = () => {
  return (
    <div style={{height: '100vh'}} className='w-100 d-flex flex-column justify-content-center align-items-center'>
      <h1>Ошибка 404</h1>
      <h2>Такая страница не найдена</h2>
      <Link to={"/"}>На главную</Link>
    </div>
  )
}

export default ErrorsPage