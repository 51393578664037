import axios from "axios"
import { BarcodeAndDocument, IGood } from "./types"



//const API_URL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENDPOINT_PREFIX}` 
// const API_URL = `https://api-dev.shop-delivery.ru/int_api/v2/mpdocs/get_act_by_code_for_tsd/`
const API_URL = `https://api.shop-delivery.ru/int_api/v2/mpdocs/`
// const API_URL = `https://api-dev.shop-delivery.ru/int_api/v2/mpdocs/`
const token = 'cmF4ZXJzQGdtYWlsLmNvbTpTYW1vbmVsYTE='

//Authorization: Basic cmF4ZXJzQGdtYWlsLmNvbTpTYW1vbmVsYTE=
//export const CUSTOMER_COMPANYS_URL = `${API_URL}v2/customers_companies/`

export const fetchGoods = (code: string) => { return axios.get<IGood>(`${API_URL}get_act_by_code_for_tsd/?code=${code}`, {
    headers: { Authorization: `Basic ${token}` }
})}

//int_api/v2/mpdocs/{id_документа}/log_scan/
// синхронизация с сервером отсканированных штрихкодов
export const syncBarcode = (data: BarcodeAndDocument) => { 
    return axios.post(`${API_URL}${data.document}/log_scan/`, 
    { barcode: data.barcode }, 
    { headers: { Authorization: `Basic ${token}`}
})}


//int_api/v2/mpdocs/log_scan_no_doc/
// синхронизация с сервером отсканированных штрихкодов без учета номера документа
export const syncBarcodeNoDoc = (barcode: string) => { 
    return axios.post(`${API_URL}log_scan_no_doc/`, 
    { barcode: barcode }, 
    { headers: { Authorization: `Basic ${token}`}
})}