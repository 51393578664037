import { createContext } from "react";

let tWindow: "main" | "modalManual" | "modalDocument"
export type TWindow = typeof tWindow

export interface ICommonContext{
    activeWindow: TWindow
    setActiveWindow: (window: TWindow) => void
}

export const initialContext: ICommonContext = {
    activeWindow: "main",
    setActiveWindow: (window) => {
        console.log('setActiveWindow', window)
    }
}

const CommonContext = createContext(initialContext)

export default CommonContext