import { FC, PropsWithChildren, useState } from "react"
import CommonContext, { ICommonContext, TWindow, initialContext } from "./commonContext"

const CommonContextProvider: FC<PropsWithChildren> = (props) => {
    const updateValue = (window: TWindow) => {
        setCommonState({...commonState, activeWindow: window})
        console.log('updateValue', window)
    }

    const [commonState, setCommonState] = useState<ICommonContext>({...initialContext, setActiveWindow: updateValue})


    return (<CommonContext.Provider value={commonState}>
        {props.children ?? null}
    </CommonContext.Provider>)
}

export default CommonContextProvider