import { FC } from "react"
import { Col, Row } from "react-bootstrap"

interface IGoodRowProps{
    className?: string
    completed?: boolean
    found?: boolean
    barcode: string
    children?: any
    onClick?: any //(barcode: string) => void
}

const GoodRow: FC<IGoodRowProps> = (props) => {
    const found = (props.completed && props.found)
      ? 'bg-success' 
      : ((props.completed && !props.found)
        ? 'bg-danger'  
        : 'bg-white')

    return <tr 
      onClick={() => props.onClick && props.onClick(props.barcode)} 
      className={`${props.className ?? ''} ${found}`}>
      {props.children}
  </tr>
}

export default GoodRow