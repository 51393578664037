import { FC, PropsWithChildren,  useContext,  useEffect,  useRef, useState } from "react"
import ContentRow from "../../components/ContentRow"
import { Button,  } from "react-bootstrap"
import ModalWindowExtended from "../../components/ModalWindowExtended"
import { IGood, TResult } from "../../store/goods/types"
import { ResultToString } from "../../App"
import ItemsTable from "../../components/ItemsTable"
import { messageUtils } from "../../api/utils"
import CommonContext from "../../config/context/commonContext"

interface IManualWrapperProps{
    lastStatus: TResult
    totalItems: number
    foundItems: number
    items: IGood[] | null
    isLoading: boolean
    lastCode: string | null
    rightTitleChildren?: any

    setItems: (items: string | null) => void
}

const ManualView: FC<PropsWithChildren<IManualWrapperProps>> = (props) => {
    const inpManualModal: any = useRef(null)
    const commonContext = useContext(CommonContext)

    const [showManualModal, setShowManualModal] = useState<boolean>(false)
    const [manualInputDoc, setManualInputDoc] = useState<string>("")

    useEffect(() => {
        focusOnCurrentInput()
    }, [])

    const focusOnCurrentInput = () => {
        inpManualModal?.current?.focus()
    }

    const openModal = (show: boolean) => {
        commonContext.setActiveWindow(show ? "modalManual" : "main")
        setShowManualModal(show)
    }

    const manualSetDoc = (documContent: string) => {
        messageUtils.question("Текущие коды будут утрачены. Продолжить?", props.setItems, documContent)
      }

    return (<>
        <ContentRow className={`App-title-placeholder ${ResultToString(props.lastStatus).color}`}>
            <div className={`nakStatus border border-1 ${props.foundItems == props.totalItems ? 'text-white bg-success' : 'text-dark bg-white'}`}>{props.foundItems}/{props.totalItems}</div>
            {props.rightTitleChildren ? <div className={`nakStatusRight border border-1`}>{props.rightTitleChildren}</div> : null}
                <div className="p-2 rounded bg-white mb-1">
                    {ResultToString(props.lastStatus).text}
                </div>
                {props.lastStatus 
                    ? <div className="d-flex flex-row align-items-center">
                        <h6 className="me-2">Код:</h6>
                        <h5>{props.lastCode}</h5>
                    </div> 
                    : null}
        </ContentRow>
        {props.children ? <ContentRow className="App-input-placeholder">{props.children}</ContentRow> : null}
        <ContentRow 
            className={`App-content-placeholder table-responsive`} 
            justifyContent={props.items?.length && !props?.isLoading ? "start" : "center"}
        >
            {
                !props.items?.length && !props?.isLoading
                    ? <div className="d-flex flex-column">
                        <p>Занесите коды</p>
                        <Button variant="primary" onClick={() => openModal(true)}>Занести</Button>
                    </div>
                    : null
            }
            {
                props.isLoading
                    ? <p>Загрузка...</p>
                    : (props?.items?.length
                        ? <ItemsTable
                            items={props.items}
                            mode="manual"
                            clearTable={() => manualSetDoc("")}
                            />
                        : null)
            }
        </ContentRow>
        <ModalWindowExtended 
                fullscreen={true} 
                show={showManualModal} setShow={openModal} 
                canApply={true}
                applyText='Подтвердить' cancelText='Отмена'
                applyAction={() => manualSetDoc(manualInputDoc)}
                onClick={() => focusOnCurrentInput()}
            >
                <h3 className="text-center w-100">Внесите вручную коды</h3>
                <p className="text-center w-100">по одному коду на строку</p>
                <textarea style={{ height: '80%' }} 
                    ref={inpManualModal} 
                    className="App-input" 
                    autoFocus={showManualModal} 
                    value={manualInputDoc} 
                    onChange={(e) => setManualInputDoc(e.currentTarget.value)} />
                <Button variant="outline-dark" size="sm" onClick={() => 
                    messageUtils.question("Удалить все содержимое поля?", setManualInputDoc, "")}>Очистить поле</Button>
        </ModalWindowExtended>
    </>)
}

export default ManualView