import { FC, PropsWithChildren, useContext, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { addGoodCheckCreator, clearDocumentCreator, getAsyncGoodsCreator, setAsyncLogGoodScanCreator, setGoodCheckCreator, setLastResultCreator, setManualGoodsCreator, setModeCreator } from "../../store/goods/actions"
import { IGood, IGoodsState, TMode, TResult } from "../../store/goods/types"
import { messageUtils } from "../../api/utils"
import DocumentView from "./DocumentView"
import AutoView from "./AutoView"
import ManualView from "./ManualView"
import { StorageType } from "../../store/rootReducer"
import { setIsScannerMode, setLastCode } from "../../store/goods"
import useSound from "use-sound"
import { EMPTY_DOC_ID } from "../../store/goods/constants"
import 'rc-switch/assets/index.css'
import { useDebounce } from "../../helpers/helpers"
import ScannerModeSwitcher from "../../components/ScanerModeSwitcher"
import CommonContext from "../../config/context/commonContext"

interface IManualWrapperProps {
    mode: TMode
    randomNumber?: number // for event when clicked on common Container in App.tsx
}

// return number of founded items (need to check Synced in auto mode)
export const checkedNumber = (arr: IGood[], autoMode?: boolean) => {
    return autoMode 
        ? arr.filter(a => a.found && a.synced).length
        : arr.filter(a => a.found).length
}

// use to make onchange event in input even when content didn't change
// returns current tick and symbol to split source code and this tick
const getDifferentator = () => {
    return `_><_${new Date().valueOf().toString()}`
}

// use to make onchange event in input even when content didn't change. 
// this code deletes data, which generated and added to the end of code
// return source code
const removeDifferentator = (inputString: string) => {
    return inputString.split('_><_')[0]
}

const CommonWrapper: FC<PropsWithChildren<IManualWrapperProps>> = (props) => {
    const dispatch = useDispatch()
    const inp: any = useRef(null)

    const commonContext = useContext(CommonContext)

    const [playFound] = useSound('./../assets/sounds/success_main.mp3');
    const [playNotFound] = useSound('./../assets/sounds/not_found_main.mp3');
    const [playExist] = useSound('./../assets/sounds/2.mp3');

    
    const goodState: IGoodsState = useSelector((state: StorageType) => state.goods)
    
    const [inputGood, setInputGood] = useState<string>("")
    const [barcodeTerm, setBarcodeTerm] = useState('')
    
    const debouncedTerm = useDebounce(barcodeTerm, 500)

    const focusOnInput = () => {
        inp?.current?.focus();
    }

    useEffect(() => {
        dispatch(setModeCreator(props.mode))
    }, [props.mode])

    // change focus
    useEffect(() => {
        focusOnInput();
    }, [])
    useEffect(() => {
        if (inputGood.length > 0){
            goodState.isScannerMode && setBarcodeTerm(inputGood + `${getDifferentator()}`)
        }
        focusOnInput();
    }, [inputGood])
    useEffect(() => {
        focusOnInput();
    }, [goodState.items])

    useEffect(() => {
        if (props.randomNumber && commonContext.activeWindow == "main"){
            focusOnInput();
        }
    }, [props.randomNumber])

    useEffect(() => {
        if (commonContext.activeWindow == "main"){
            setTimeout(() => {
                focusOnInput();
            }, 200)  
        }
    }, [commonContext.activeWindow])



    useEffect(
        () => {
            if (debouncedTerm !== undefined && barcodeTerm !== undefined) {
                // отправка кода
                setChecked(removeDifferentator(barcodeTerm))
            }
    }, [debouncedTerm])

    useEffect(() => {
        if (goodState.isScannerMode && inputGood?.length){
            setBarcodeTerm(inputGood)
            focusOnInput();
        }
    }, [goodState.isScannerMode])


    const checkCode = (code: string) => {
        if ((code == "F23") || code == "Enter") {
            !goodState.isScannerMode && setChecked(inputGood)
            inp.current.value = ""
        }
    }

    const setChecked = (barcode: string) => {
        const rowResult = goodState.items.filter(i => i.barcode == barcode)
    
        if (rowResult?.length) {
          if (rowResult[0].found || goodState.mode == "auto") {
            dispatch(setLastResultCreator("exist"))
            inp.current.value = ""
            playExist()
          }
          else {
            dispatch(setGoodCheckCreator(barcode))
            dispatch(setAsyncLogGoodScanCreator())
            dispatch(setLastResultCreator("found"))
            inp.current.value = ""
            playFound()
          }
        }
        else {
          if (goodState.mode == "auto"){
            dispatch(addGoodCheckCreator(barcode))
            dispatch(setAsyncLogGoodScanCreator())
            inp.current.value = ""
          }
          else{
              if (barcode?.length) {
                  dispatch(setLastResultCreator("not_found"))
                  inp.current.value = ""
                  playNotFound()
              }
          }
        }
    
        dispatch(setLastCode(barcode))
        setInputGood("")
        focusOnInput();
      }

    const getDocumentById = (docId: string, noprompt?: boolean) => {
        if (noprompt){
            dispatch(docId != EMPTY_DOC_ID.toString() ? getAsyncGoodsCreator(docId) : clearDocumentCreator())
        }
        else{
            if (docId != EMPTY_DOC_ID.toString()){
                messageUtils.question("Уверены, что хотите сменить накладную?", dispatch, getAsyncGoodsCreator(docId))
            }
            else{
                messageUtils.question("Уверены, что хотите очистить поля?", dispatch, clearDocumentCreator())
            }
        }
        focusOnInput();
    }

    const setManualItems = (rawContent: string | null) => {
        dispatch(setManualGoodsCreator(rawContent ?? ""))
        dispatch(setLastResultCreator("init"))
        dispatch(setLastCode(""))
        
        setInputGood("")
        inp!.current.value = ""
        focusOnInput();

    }

    const renderView = () => {
        switch (props.mode) {
            case "manual": return <ManualView
                foundItems={checkedNumber(goodState.items)}
                isLoading={goodState.isLoading}
                totalItems={goodState.count}
                items={goodState.items}
                lastStatus={goodState.lastResult}
                lastCode={goodState.lastCode}
                setItems={setManualItems}
                rightTitleChildren={ <ScannerModeSwitcher isScannerMode={goodState.isScannerMode} setIsScannerMode={(value) => dispatch(setIsScannerMode(value))} /> }
            >
                <input type="text" className="App-input"
                    title="При вводе кодов с клавиатуры после ввода последнего символа нажимайте Enter"
                    ref={inp}
                    onChange={(e) => setInputGood(e.currentTarget.value)}
                    onKeyUp={(e) => checkCode(e.code)}
                />
            </ManualView>;
            case "document": return <DocumentView
                foundItems={checkedNumber(goodState.items)}
                isLoading={goodState.isLoading}
                totalItems={goodState.count}
                items={goodState.items}
                lastStatus={goodState.lastResult}
                lastCode={goodState.lastCode}
                loadedDocument={goodState.loadedDocument}
                getItems={getDocumentById}
                rightTitleChildren={ <ScannerModeSwitcher isScannerMode={goodState.isScannerMode} setIsScannerMode={(value) => dispatch(setIsScannerMode(value))} /> }
            >
                <input type="text" className="App-input"
                    title="При вводе кодов с клавиатуры после ввода последнего символа нажимайте Enter"
                    ref={inp}
                    onChange={(e) => setInputGood(e.currentTarget.value)}
                    onKeyUp={(e) => checkCode(e.code)}
                />
            </DocumentView>;
            case "auto": return <AutoView
                foundItems={checkedNumber(goodState.items, true)}
                isLoading={goodState.isLoading}
                totalItems={goodState.count}
                items={goodState.items}
                lastStatus={goodState.lastResult}
                lastCode={goodState.lastCode}
                clearItems={() => messageUtils.question("Удалить все результаты сканирования?", dispatch, clearDocumentCreator())}
                rightTitleChildren={ <ScannerModeSwitcher isScannerMode={goodState.isScannerMode} setIsScannerMode={(value) => dispatch(setIsScannerMode(value))} /> }
            >
                <input type="text" className="App-input"
                    title="При вводе кодов с клавиатуры после ввода последнего символа нажимайте Enter"
                    ref={inp}
                    onChange={(e) => setInputGood(e.currentTarget.value)}
                    onKeyUp={(e) => checkCode(e.code)}
                />
            </AutoView>;
            default: return <p>Режим не определен</p>
        }
    }

    return (
        <>
            {renderView()}


        </>
    )
}

export default CommonWrapper