import { IGood, IGoodsState, TResult } from './types';
import { call, delay, put, select, takeEvery } from "redux-saga/effects"
import { fetchGoods, syncBarcode, syncBarcodeNoDoc } from "./api";
import {  addCheckedGood, setCheckedGood, setClearState, setCount, setDocument, setGoods, setIsLoading, setIsScannerMode, setIsSyncing, setMode, setResult, setSyncedGood } from "./reducers"
import { ADD_GOOD_CHECK, ASYNC_GET_GOODS, ASYNC_LOG_GOOD_SCAN_START, CLEAR_DOC, MANUAL_GET_GOODS, SET_AUTO_MODE, SET_DOC, SET_GOOD_CHECK, SET_IS_SCANNER_MODE, SET_LAST_RESULT, SET_MODE } from "./actions"
import { StorageType } from '../rootReducer';

const interval = 1000 // интервал синхронизации


export function* goodSaga(){
    yield takeEvery(ASYNC_GET_GOODS, workerGetGoods)
    yield takeEvery(MANUAL_GET_GOODS, workerManualSetGoods)
    yield takeEvery(CLEAR_DOC, workerClearDoc)
    yield takeEvery(SET_DOC, workerSetDoc)
    yield takeEvery(SET_GOOD_CHECK, workerSetGoodCheck)
    yield takeEvery(ADD_GOOD_CHECK, workerAddGoodCheck)
    yield takeEvery(ASYNC_LOG_GOOD_SCAN_START, workerStartLogGoodScan)
    yield takeEvery(SET_MODE, workerSetMode)
    yield takeEvery(SET_IS_SCANNER_MODE, workerSetIsScannerMode)
    yield takeEvery(SET_LAST_RESULT, workerSetLastResult)
}


export function* workerGetGoods(action: any):any{

    try{

        yield put(setIsLoading(true))
        const goodsPromise = yield call(() => fetchGoods(action.payload))
        if (goodsPromise.data){
            yield put(setClearState())
            yield put(setGoods(goodsPromise.data))
            yield put(setCount(goodsPromise.data.length))
            yield put(setDocument(action.payload))
        }
    }
    catch(error){
        console.log(error)
    }
    finally{

        yield put(setIsLoading(false))
    }

}



export function* workerManualSetGoods(action: any):any{

    try{
        yield put(setIsLoading(true))
        yield put(setClearState())
        const str: string = action.payload
        const strarr = str.split('\n')?.filter(s => s.trim() != "") ?? []
        let goods: IGood[] = Array.isArray(strarr ?? "") 
        ? strarr.map((s: string) => {
            return {
                order_number: "",
                barcode: s,
                name: s,
                    found: false
                }
            })
            : []
        yield put(setGoods(goods))
        yield put(setCount(goods.length))

    }
    catch(error){
        console.log(error)
    }
    finally{

        yield put(setIsLoading(false))
    }

}

export function* workerClearDoc():any{
    // const customersPromise = yield call(fetchCustomerCompanys)
    // yield put(setCustomerCompanys(customersPromise.data.results))
    yield put(setClearState())
}

export function* workerSetDoc():any{
    //const customersPromise = yield call(fetchCustomerCompanys)
    //yield put(setCustomerCompanys(customersPromise.data.results))
}


export function* workerSetMode(action: any):any{
    yield put(setClearState())
    yield put(setMode(action.payload))
}

export function* workerSetIsScannerMode(action: any):any{
    yield put(setIsScannerMode(action.payload))
}

export function* workerSetLastResult(action: any):any{
    yield put(setResult(action.payload))
}

export function* workerSetGoodCheck(action: any):any{
        yield put(setCheckedGood(action.payload))
}

// add good to items (for automode)
export function* workerAddGoodCheck(action: any):any{
        yield put(addCheckedGood(action.payload))
}

// запускает асинхронный цикл отправки
export function* workerStartLogGoodScan():any{
    let goodState: IGoodsState = yield select((storage: StorageType) => storage.goods)
    let notSynced = goodState.items.filter(g => g.found && !g.synced)
    if (!goodState.isSyncing)
    {
        // console.log("sync start")
        yield put(setIsSyncing(true))
        while(notSynced && notSynced.length > 0){
            // yield checkSyncTimer()
            if (goodState.mode == "auto" || goodState.loadedDocument?.length){
                yield workerLogGoodScan()
            }
            yield delay(interval)
            // console.log("sync working")
            goodState = yield select((storage: StorageType) => storage.goods)
            notSynced = goodState.items.filter(g => g.found && !g.synced)
        }
        yield put(setIsSyncing(false))
        // console.log("sync stop")
    }

    // setInterval(() => {
        // console.log("setInterval checkSyncTimer")
        // const generator = checkSyncTimer()
        // generator.next()
    // }, 5000)
}
export function* workerLogGoodScan():any{
    const goodState: IGoodsState = yield select((storage: StorageType) => storage.goods)
    const firstNotSynced: IGood | undefined = goodState.items.find(g => g.found && !g.synced)
    try{
        if (firstNotSynced){
            const goodsPromise = goodState.mode == "auto"
                ? yield call(() => syncBarcodeNoDoc(firstNotSynced.barcode))
                : yield call(() => syncBarcode({barcode: firstNotSynced.barcode, document: goodState.loadedDocument ?? ""}))
                goodsPromise?.data && (yield put(setSyncedGood({barcode: firstNotSynced.barcode, success: goodsPromise.data.success, name: goodsPromise.data.data?.act ?? ""})))
                if (goodState.mode == "auto"){
                    yield put(setCount(goodState.items?.length ?? 0))
                    yield put(setResult(goodsPromise.data.success ? "init" as TResult : "init" as TResult))
                }
        }
    }
    catch(error: any){
//         *42cs+Rx	
// 1137970966
// 2	*42c9oNx
        // console.log(error?.response?.data?.errors?.length )
        if (firstNotSynced?.barcode 
            && error?.response?.data?.errors?.length 
            && error.response.data?.errors[0] 
            && error.response.data?.errors[0].title
            && typeof(error.response.data.errors[0].title) == "string"
            ){
                if  ((error.response.data.errors[0].title as string).includes("DoesNotExist"))
                {
                    yield put(setSyncedGood({barcode: firstNotSynced.barcode, success: false, name: ""}))
                    yield put(setCount(goodState.items?.length ?? 0))
                    yield put(setResult("init" as TResult))
                }
                if  ((error.response.data?.errors[0].title as string).includes("MultipleObjectsReturned")){
                    yield put(setSyncedGood({barcode: firstNotSynced.barcode, success: false, name: ""}))
                    yield put(setCount(goodState.items?.length ?? 0))
                    yield put(setResult("init" as TResult))
                }
            }
        //

    }
    finally{

        // yield put(setIsSyncing(false)) // это пока здесь не нужно
    }

}


  