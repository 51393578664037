import Switch from "rc-switch"
import { FC } from "react"

interface IScannerModeSwitcherProps {
    isScannerMode: boolean
    setIsScannerMode: (value: boolean) => void
}


const ScannerModeSwitcher: FC<IScannerModeSwitcherProps> = (props) => {
    return <div className="d-flex flex-column align-items-center justify-content-center px-0 pt-2 bg-white">
    <Switch checked={props.isScannerMode}
        onChange={() => props.setIsScannerMode(!props.isScannerMode)}
        checkedChildren="on"
        unCheckedChildren="off"
        title="Включите этот режим, если вы сканируете через ручной сканер, который не отправляет код клавиши Enter после считывания кода"
    />
    <p title="Включите этот режим, если вы сканируете через ручной сканер, который не отправляет код клавиши Enter после считывания кода"
        className={`my-1 mx-0 px-1 fs-9 rounded ${props.isScannerMode ? 'bg-warning' : ''}`}>Без Enter</p>
</div>
}

export default ScannerModeSwitcher