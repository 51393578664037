import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import App from "./App";
import ErrorsPage from "./ErrorsPage";
import MessagePage from "./MessagePage";
import AutotWrapper from "./layout/panels/AutoView";
import ManualWrapper from "./layout/panels/ManualView";
import DocumentWrapper from "./layout/panels/DocumentView";
import CommonWrapper from "./layout/panels/CommonWrapper";
import { useState } from "react";


const AppRoutes = () => {

  const [randomNumber, setRandomNumber] = useState<number>()

  const appClickHandler = () => {
    setRandomNumber(Math.random())
  }

return <BrowserRouter>
  <Routes>
    <Route path="/" element={<App clickHandler={appClickHandler} />}>
      <Route index element={
        <CommonWrapper mode="manual" randomNumber={randomNumber} />
      } />
      <Route path="id/*" element={<Outlet />}>
        <Route index element={
          <MessagePage 
            name="Не указан Id" 
            info="Укажите какой id необходимо загрузить" 
            btnTitle="На главную"
            btnHref="/"
            />
            } />
        <Route path=':id' element={<CommonWrapper mode="document" randomNumber={randomNumber} />} />
      </Route>
      <Route path="auto" element={<CommonWrapper mode="auto" randomNumber={randomNumber} />} />
    </Route>
    <Route path='*' element={<ErrorsPage />} />
  </Routes>
</BrowserRouter>
}

export default AppRoutes