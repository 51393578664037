import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import createSagaMiddleware from 'redux-saga'
import { rootReducer, rootSaga } from "./rootReducer";
import {persistStore} from 'redux-persist'


let sagaMiddleware = createSagaMiddleware({
    onError: (er: Error, saga: any) => 
      console.log('saga error', er, saga)
})


const middleware = [
    ...getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      thunk: true,
    }),
    sagaMiddleware,
  ]

  const store = configureStore({
    reducer: rootReducer,
    middleware,
    devTools: process.env.NODE_ENV !== 'production',
    //enhancers: [reduxBatch],
  })
  
  export type AppDispatch = typeof store.dispatch
  
  /**
   * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
   * @see https://github.com/rt2zz/redux-persist#persistor-object
   */
  export const persistor = persistStore(store)
  
  
  sagaMiddleware.run(rootSaga)
  
  export default store