import { FC, PropsWithChildren, useContext, useEffect, useRef, useState } from "react"
import ContentRow from "../../components/ContentRow"
import { Button, } from "react-bootstrap"
import ModalWindowExtended from "../../components/ModalWindowExtended"
import { IGood, TResult } from "../../store/goods/types"
import { ResultToString } from "../../App"
import ItemsTable from "../../components/ItemsTable"
import { messageUtils } from "../../api/utils"
import { useParams } from "react-router-dom"
import { EMPTY_DOC_ID } from "../../store/goods/constants"
import CommonContext from "../../config/context/commonContext"

interface IDocumentViewProps {
    lastStatus: TResult
    totalItems: number
    foundItems: number
    items: IGood[] | null
    isLoading: boolean
    lastCode: string | null
    loadedDocument: string | null
    rightTitleChildren?: any

    getItems?: (id: string, noprompt?: boolean) => void
}

const DocumentView: FC<PropsWithChildren<IDocumentViewProps>> = (props) => {
    const { id } = useParams()
    const commonContext = useContext(CommonContext)

    const inpModal: any = useRef(null)

    const [showModal, setShowModal] = useState<boolean>(false)
    const [inputDoc, setInputDoc] = useState<string>("")

    
    useEffect(() => {
        focusOnCurrentInput()
    }, [])

    useEffect(() => {
        if (id?.length && id != EMPTY_DOC_ID.toString()) {
            getDocument(id, true)
            focusOnCurrentInput()
        }
    }, [id])

    const focusOnCurrentInput = () => {
        inpModal?.current?.focus()
    }

    const openModal = (show: boolean) => {
        commonContext.setActiveWindow(show ? "modalDocument" : "main")
        setShowModal(show)
    }

    const getDocument = (docId: string, noprompt?: boolean) => {
        props.getItems && props.getItems(docId, noprompt)
    }

    return (<>
        <ContentRow className={`App-title-placeholder ${ResultToString(props.lastStatus).color}`}>
            <div className={`nakStatus border border-1 ${props.foundItems == props.totalItems ? 'text-white bg-success' : 'text-dark bg-white'}`}>{props.foundItems}/{props.totalItems}</div>
            {props.rightTitleChildren ? <div className={`nakStatusRight border border-1`}>{props.rightTitleChildren}</div> : null}
            <div className="p-2 rounded bg-white mb-1">
                {ResultToString(props.lastStatus).text}
            </div>
            {props.lastStatus
                ? <div className="d-flex flex-row align-items-center">
                    <h6 className="me-2">Код:</h6>
                    <h5>{props.lastCode}</h5>
                </div>
                : null}
        </ContentRow>
        {props.children ? <ContentRow className="App-input-placeholder">{props.children}</ContentRow> : null}
        <ContentRow
            className={`App-content-placeholder table-responsive`}
            justifyContent={props.items?.length && !props?.isLoading ? "start" : "center"}
        >
            {
                !props.items?.length && !props?.isLoading
                    ? <div className="d-flex flex-column">
                        <p>Загрузите документ</p>
                        <Button variant="primary" onClick={() => openModal(true)}>Загрузить</Button>
                    </div>
                    : null
            }
            {
                props.isLoading
                    ? <p>Загрузка...</p>
                    : (props?.items?.length
                        ? <ItemsTable
                            items={props.items}
                            mode="document"
                            rightChildren={`Документ ${props.loadedDocument ?? 'не загружен'}`}
                            clearTable={() => getDocument(EMPTY_DOC_ID.toString())}
                        />
                        : null)
            }
        </ContentRow>
        <ModalWindowExtended
            show={showModal} setShow={openModal}
            canApply={true}
            applyText='Выбрать' cancelText='Отмена'
            applyAction={() => getDocument(inputDoc)}
            onClick={() => focusOnCurrentInput()}
        >
            <input type="text" ref={inpModal} className="App-input" autoFocus={showModal} value={inputDoc} onChange={(e) => setInputDoc(e.currentTarget.value)} />
        </ModalWindowExtended>
    </>)
}

export default DocumentView