import { createSlice } from "@reduxjs/toolkit"
import { IGood, initialGoodState as initialState, } from "./types"


const goodSlice = createSlice({
    name: "goodSlice",
    initialState,
    reducers: {
        setGoods(state, action: any){
            state.items = [...action.payload.map((p: IGood) => {
                return {
                    ...p,
                    name: "",
                    found: false,
                }
            })]
        },
        setDocument(state, action){
            state.loadedDocument = action.payload
        },
        setCount(state, action){
            state.count = action.payload
        },
        setIsLoading(state, action){
            state.isLoading = action.payload
        },
        setMode(state, action){
            state.mode = action.payload
        },
        setIsScannerMode(state, action){
            state.isScannerMode = action.payload
        },
        setResult(state, action){
            state.lastResult = action.payload
        },
        setLastCode(state, action){
            state.lastCode = action.payload
        },
        setClearState(state)
        {
            // state.currentDoc = ""
            state.items = []
            state.count = 0
            state.isLoading = false
            state.isSyncing = false
            state.lastCode = ""
            state.lastResult = "init"
            state.loadedDocument = null
            // state.isAutoMode = false
        },     
        setIsSyncing(state, action){
            state.isSyncing = action.payload
        },
        addCheckedGood(state, action) {
            // console.log('reducer setCheckedGood', action.payload)
            state.items = [...state.items, {
                barcode: action.payload,
                order_number: "авторежим",
                name: "",
                found: true,
                synced: false,
            }
            ]
        },
        setCheckedGood(state, action) {
            // console.log('reducer setCheckedGood', action.payload)
            state.items = [...state.items.map((p: IGood) => {
                return {
                    ...p,
                    name: "",
                    found: p.barcode == action.payload ? true : p.found,
                    synced: p.synced,
                }
            })]
        },
        setSyncedGood(state, action) {
            // console.log('reducer setSyncedGood', action.payload)
            // debugger
            const {barcode, success, name} = action.payload
            console.log(barcode +  '=' + success ? 'true' : 'false')
            state.items = [...state.items.map((p: IGood) => {
                // debugger
                return {
                    ...p,
                    synced: p.barcode == barcode ? true : p.synced,
                    name: p.barcode == barcode ? name : p.name,
                    found: p.barcode == barcode ? success : p.found,
                }
            })]
        },
    }
})


export const goodReducer = goodSlice.reducer
export const { setGoods, setIsLoading, setCheckedGood, setSyncedGood, setIsSyncing, setResult, 
    setLastCode, setCount, setIsScannerMode,
    setDocument, setMode, setClearState, addCheckedGood } = goodSlice.actions