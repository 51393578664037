import React, { Children, useState } from "react"
import { Button, Col, Modal, Row } from "react-bootstrap"


interface IModalWindowExtendedProps {
    fullscreen?: true | string
    centered?: boolean
    scrollable?: boolean
    title?: string
    className?: string
    children: any
    cancelAction?: any
    applyAction?: any
    cancelText?: string
    applyText?: string
    canApply: boolean
    setShow: (show: boolean) => void
    size?: "sm" | "lg" | "xl"
    show: boolean
    closable?: boolean
    isLoading?: boolean
    onClick?: any
}

const ModalWindowExtended = (props: IModalWindowExtendedProps) => {


    const handleClose = (cancel: boolean = true) => {
        props.setShow(false)

        if (cancel)
            props.cancelAction && props.cancelAction() //  props.cancelAction && props.cancelAction
        else
            props.applyAction && props.applyAction() // props.applyAction && props.applyAction


    }

    return (
        
            <Modal
                scrollable={props.scrollable ?? false}
                fullscreen={props.fullscreen}
                centered={props.centered ?? false}
                onHide={handleClose}
                show={props.show}
                size={props.size ?? "lg"}
                className={props.className}
                backdrop={props.closable ? "static" : false}
            >
                {props?.isLoading ?
                    <>
                        <Modal.Header></Modal.Header>
                        <Modal.Body>
                            Loading...
                        </Modal.Body>
                        <Modal.Footer></Modal.Footer>
                    </>
                    :
                    <>
                        <Modal.Header onClick={props.onClick && props.onClick}>
                            <h5 className="modal-title">{props.title}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                onClick={() => handleClose()}
                                aria-label="Close"
                            >
                                
                            </div>
                        </Modal.Header>
                        <Modal.Body onClick={props.onClick && props.onClick}>
                            {props.children}
                        </Modal.Body>
                        <Modal.Footer onClick={props.onClick && props.onClick}>
                            <Row>
                                <Col>
                                    <button
                                        type="button"
                                        className="btn btn-light"
                                        onClick={() => handleClose()}
                                    >
                                        {props.cancelText ?? "Cancel"}
                                    </button>
                                </Col>
                                <Col>
                                    {/* <NotifiedButton/> */}
                                    <button
                                        type="button"
                                        disabled={!props.canApply}
                                        onClick={() => handleClose(false)} // false parameter means call 'props.applyAction'
                                        className="btn btn-primary">
                                        {props.applyText ?? "Ok"}
                                    </button>
                                </Col>
                            </Row>
                        </Modal.Footer>
                    </>
                }
            </Modal>
    )
}

export default ModalWindowExtended