export const initialGoodState: IGoodsState = {
    count: 0,
    items: [],
    loadedDocument: null,
    // currentDoc: "",
    // isAutoMode: false,
    mode: "manual",
    isLoading: false,
    isSyncing: false,
    lastResult: "init",
    lastCode: null,
    isScannerMode: false,
}

export const initialGood: IGood = {
    barcode: "",
    found: false,
    synced: false,
    name: "",
    order_number: "",
}

let mode: "document" | "manual" | "auto" | "notset"
export type TMode = typeof mode

let result: "found" | "exist" | "not_found" | "error" | "init"
export type TResult = typeof result

export interface IGoodsState{
    count: number
    items: IGood[]
    loadedDocument: string | null
    // isAutoMode: boolean
    mode: TMode
    isLoading: boolean
    isSyncing: boolean
    lastResult: TResult
    lastCode: string | null
    isScannerMode: boolean
}

export interface IGood{
    order_number?: string
    barcode: string
    name?: string
    found?: boolean
    synced?: boolean
}

export interface BarcodeAndDocument{
    barcode: string
    document: string
}

export const customerActionTypes = {
    SetMode: '[SetMode] Action',
    SetLastResult: '[SetLastResult] Action',
    GetGoods: '[GetGoods] Action',
    LogGoodScanStart: '[LogGoodScanStart] Action',
    SetAutoMode: '[SetAutoMode] Action',
    ManualSetGoods: '[ManualSetGoods] Action',
    SetDocument: '[SetDocument] Action',
    ClearDocument: '[ClearDocument] Action',
    SetGoodCheck: '[SetGoodCheck] Action',
    AddGoodCheck: '[AddGoodCheck] Action',
    SetScannerMode: '[SetScannerMode] Action',
  }