import { FC } from "react"
import { Col, Row } from "react-bootstrap"

interface IContentRowProps{
    className?: string
    title?: string
    children?: any
    justifyContent?: string
    alignItems?: string
}

const ContentRow: FC<IContentRowProps> = (props) => {
    return <Row className={props.className ?? ''}>
    <Col className={`d-flex flex-column 
    justify-content-${props.justifyContent ? props.justifyContent : `center`} 
    align-items-${props.alignItems ? props.alignItems : `center`}`}>
      {props.title ? <div>{props.title}</div> : null}
      {props.children}
    </Col>
  </Row>
}

export default ContentRow