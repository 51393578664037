import { FC, PropsWithChildren } from "react"
import { IGood, TMode } from "../store/goods/types"
import GoodRow from "./GoodRow"
import { Button } from "react-bootstrap"


interface IItemsTableProps {
    items: IGood[] | null
    mode: TMode
    clearTable?: () => void
    rightChildren?: any
}

const ItemsTable: FC<PropsWithChildren<IItemsTableProps>> = (props) => {
    const isAutoMode = props.mode == "auto"


    return (
        <table className='table table-hover '>
            <tbody>
                {
                    props.items?.length
                        ? <tr>
                            <td colSpan={2}>
                                <div className="w-100 fs-8 d-flex flex-row justify-content-start align-items-center py-3 ">{props.rightChildren ? props.rightChildren : null}</div>
                            </td>
                            <td>
                                <div className="w-100 d-flex flex-row justify-content-end p-2">
                                    <Button size="sm" variant="outline-secondary" onClick={props.clearTable}>Очистить</Button>
                                </div>
                            </td>
                        </tr>
                        : null
                }
                {
                    props.items
                        ?.slice()  // копирует исходный массив полностью, чтобы потом можно было его изменять с помощью sort
                        .sort((a, b) => (a.found ? 1 : 0) - (b.found ? 1 : 0)) // найденые уходят вниз
                        .map((g, i) =>
                            <GoodRow
                                barcode={g.barcode}
                                completed={isAutoMode ? g.synced : g.found}
                                found={g.found}
                                key={i}>    
                                <td>{i + 1}</td>
                                <td>{g.barcode}</td>
                                <td className="">
                                    <div>{isAutoMode ? (g.found ? g.name : "Не найден") : g.order_number}</div>
                                    {
                                        (props.mode == "auto" || props.mode == "document")
                                            ? <div style={{ width: 'auto' }}
                                                className={`badge ${g.synced ? 'bg-primary' : 'bg-danger'} text-white fs-10`}
                                            >
                                                {g.synced ? 'synced' : 'not synced'}
                                            </div>
                                            : null
                                    }
                                </td>
                            </GoodRow>
                        )
                }
            </tbody>
        </table>
    )
}

export default ItemsTable