import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'

import * as storage from './index'

export interface StorageType{
  goods: any
}

const storageObj: StorageType = {
  goods: storage.goodReducer,
}

// export const rootReducer = combineReducers({...storageObj, auth: auth.reducer})
export const rootReducer = combineReducers({...storageObj})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([
    storage.goodSaga(),
  ])
}
